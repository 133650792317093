import React from 'react'
import parse from 'html-react-parser'

import data from '../../../data'

import { GoHome } from '../../components/Cta'
import ItemHero from './Hero';

import SectionDisplay from '../../components/ImageDisplay'

import './index.css'

const CTA = ({link, text}) => (
  <div className="cta action">
    <img alt="next item arrow" src="images/arrow-CTA.svg" />

    <a href={link}>
      {text}
    </a>
  </div>
)

const CtaStart = ({idx}) => {
  return (
    <div className="cta start">
      <a 
        href={`#${data[idx].route}`}
      >
        Back to First Project
      </a>
    </div>
  )
}

const CtaNext = ({idx}) => (
  <div className="cta next">
    <a 
      href={`#${data[idx].route}`}
    >
      Next Project
    </a>
    <img alt="next item arrow" src="images/arrow-CTA.svg" />
  </div>
)

const NavCta = ({idx}) => {
  const nextIdx = (idx + 1) % data.length
  const isLast = nextIdx === 0 
  
  if (isLast) return <CtaStart idx={nextIdx} />
  
  return <CtaNext idx={nextIdx} />
}

const ItemDetail =
        datum => {
          const { idx } = datum
          const { caption, copy, ...headerImage } = datum.headerImage
          window.scrollTo(0, 0)

          return (

<div className="pagecontent">
    <ItemHero {...datum} />
    <SectionDisplay  {...headerImage} />
    <div className="description">
      <div className="description-text">
        { parse(datum.description.copy)}
        {
          datum.description.CTA
          ? <CTA {...datum.description.CTA} />
          : null
        }
      </div>
      <div className="description-team">
        <p>
          <strong>
            Team
          </strong>
        </p>
        <ul>
          { datum.team.map(
              member => (
                <li key={member}>
                  {member}
                </li>
              )
            )
          }
        </ul>
      </div>
    </div>
    <div className="content">
      { datum.sections.map(
          (sectionInfo, idx) => {
          return <SectionDisplay key={idx} {...sectionInfo} />
          }
        )
      }
    </div>
    <div className="nav">
      <NavCta idx={idx} />
      <GoHome />
    </div>
</div>
)
}

export default ItemDetail
