import mina from './mina'
import mina_foundation from './mina_foundation'
import blockaid from './blockaid'
import gridspace from './gridspace'
import sterling from './sterling'

const pages =  [
  mina,
  mina_foundation,
  blockaid,
  gridspace,
  sterling
]

export default pages
