import React from 'react'
import MenuItem from './MenuItem'

const pages = [
['', 'Home']
, ['Resume', 'Resumé']
, ['About', 'About']
, ['Contact', 'Contact']
]

const Pages = () => pages.map(
  ([location, name]) => 
    <MenuItem name={name} key={name} location={location} />
)

export default Pages
