import React from 'react'

import Menu from '../components/Header'
import {TailNoContact} from '../components/Tail'

import './index.css'
    
const Contact = ({data, transitionFrom}) => (
  <div className="page">
    <div className="pagecontent heropagecontent">
      <Menu/>
    </div>
    <TailNoContact />
 
  </div>
  )

export default Contact
