import React, { useState } from 'react'
import parse from 'html-react-parser'

import './style.css'

import Animation from '../Animation'

const Lightbox = ({children}) => {
  const [isLightBoxed, setLightBox] = useState(false)

  const className =
    isLightBoxed
    ? "img-box islightboxed"
    : "img-box"

  const onClick =
    () => setLightBox(!isLightBoxed)

  return (
    <div className={className} onClick={onClick}>
      { children }
    </div>
  )
}
const Image =
        ({alt
        , src
      }) => {
        return (
          <Lightbox>
            <img className="product-img" alt={alt} src={src} />
          </Lightbox>
        )
      }

const ImageCopy =
  ({copy}) => (
    <div className="description">
      <div className="description-text">
        { parse(copy.text) }
      </div>
      {
        copy.team ?
        <div className="description-team">
          <p>
            <strong>
              Team
            </strong>
          </p>
          <ul>
            { copy.team.map(
                member => (
                  <li key={member}>
                    {member}
                  </li>
                )
              )
            }
          </ul>
        </div>
        : null
      }
    </div>
  )

const ImageDisplay =
        imageInfo => {
          return (
          <div className="image-display">
                { imageInfo.copy
                  ? <ImageCopy copy={imageInfo.copy} />
                  : null
                }
                { imageInfo.caption && !imageInfo.hideTitle
                  ? <div className="img-caption">  { parse(imageInfo.caption) } </div>
                  : null
                }
                { imageInfo.video && imageInfo.controls && imageInfo.nolightbox
                  ? (
                    <video className="product-img" autoPlay controls loop muted>
                      <source src={imageInfo.video} type="video/mp4" />
                    </video>
                    )
                  : null
                }
                { imageInfo.video && imageInfo.controls && !imageInfo.nolightbox
                  ? ( <Lightbox>
                        <video className="product-img" autoPlay controls loop muted>
                          <source src={imageInfo.video} type="video/mp4" />
                        </video>
                      </Lightbox>
                    )
                  : null
              }

              { imageInfo.video && !imageInfo.controls
                ? ( <Lightbox>
                      <video className="product-img" autoPlay loop muted>
                        <source src={imageInfo.video} type="video/mp4" />
                      </video>
                    </Lightbox>
                  )
                : null
              }
              { imageInfo.image
                ? <Image alt={imageInfo.title} src={imageInfo.image} />
                : null
              }
              { imageInfo.animation
                ? <Animation alt={imageInfo.title} data={imageInfo.animation} />
                : null
              }
        </div>
        )}

export default ImageDisplay
