const mina_foundation = { 
    route: `mina_foundation`
    , title: `Mina Foundation`
    , headline: `Designing a company's internal processes and ways of working, from the ground up`
    , CTA : {
        text: `Return to Home Page`
    , link: '/'
    }
    , description: {
        copy:  `
        <p>
        <strong>
        Mina Foundation: The not-for-profit steward of Mina Protocol.
        </strong>
        <br /><br />
        When Mina’s mainnet launched in 2021, the team branched out to start the Mina Foundation, the entity responsible for stewarding Mina Protocol. The foundation serves to fund businesses, manage the brand, guide and educate the community, steward governance of the decentralized community, and maintain the blockchain.
        <br /><br />
    As part of the founding team, I not only directed the new brand development and extension to social and presentation templates, but also developed a set of internal tools to take some of the lift off our already laden work plates. Tools like project briefs and retro documents provided structure to the rapidly growing team, and more educational guides like How to Give and Receive Feedback or Visioning Cards provided a layer of extra support and training, skills not often provided at companies but much needed nonetheless for effective collaborations.
    <br /><br />
    The common theme for my work at Mina Foundation was to use design principles and tools to make creating content—be it marketing, product, or community—easy for everyone.
    `
    }
    , team : [
        "Stephanie Szarwark, designer",
        "Jonathan Baird, developer",
        "Andrea Rodriguez, marketing",
        "Madeline Hengst, marketing",
        "Claire Kart, marketing",
        "Sherry Lin, marketing",
        "Natasha Carter, marketing",
        "Evan Shapiro, CEO",
    ]
    , headerImage: {
        caption: `Mina Foundation`
        , image: `media/MinaFoundation/1_MF_HERO_Website2023.png`
    }
    , sections : [
        {
        caption: `Wordmark`
        , image: `media/MinaFoundation/2_MF_Wordmark_Website2023.png`
        }
    ,   {
        caption: `Illustration Style`
        , image: `media/MinaFoundation/3_MF_Illustration_Website2023.png`
        }
    , {
        image: `media/MinaFoundation/4_MF_InternalTools_Website2023.png`
        , caption: `Internal Tools and Guides`
        }
    , {
        image: `media/MinaFoundation/5_MF_AnnualReportLetter_Website2023.png`
        , caption: `Annual Report Letter`        
        }    
    , {
        image: `media/MinaFoundation/6_MF_AnnualReport_Website2023.png`
    , caption: `Annual Report Excerpts`   
    }
    , {
        video: `media/MinaFoundation/7_MF_SiteFlow_Website2023.mp4`
        , caption: `Mina Foundation Website <i>Design credit: Stephanie Szarwark and Jonathan Baird</i>`
        , copy: {
            text:`
            <strong>Website</strong>
            <br /><br />
            The Mina Foundation website had very few requirements from a marketing perspective, but a key one was that it needed to be very easy for the digital marketers to update for quarterly transparency reports. 
            <br /><br />
            I directed a minimal website intended to be lightly informative but not take the spotlight away from the Mina Protocol website. We worked hand-in-hand with the developer to create an easy-to-edit content page where non-designers could make regular updates with very little effort. 
            `      }  
    }
    , {
        image: `media/MinaFoundation/8_MF_QTR_Website2023.png`
    , caption: `Website Quarterly Report page. <i>Design credit: Stephanie Szarwark</i>`        
        }    

    ]
}

export default mina_foundation