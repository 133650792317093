import React from 'react'

import './index.css'

import Project from '../../components/Project'

import projectData from '../../../data'

const LatestProject = () => {
  
  return (
  <div className="latest-project">
      Latest Project: {projectData[0].title}
    <div className="latest-project-holder">
      <Project {...projectData[0]} hideTitle={true} />
    </div>
  </div>
)}

export default LatestProject
