import React from 'react'

import './index.css'

import Project from '../../components/Project'

import projectData from '../../../data'
import { KnowMoreEmail } from '../../components/Cta/Email'
const SelectedWork = ({transitionFrom}) => {
  
  const [ headlineProject, ...projects ] = projectData
  
  return (
  <div className="selected-work">
    <h1>
      Select work
    </h1>
    <div className="select cta">
        <img alt="next item arrow" src="images/arrow-CTA.svg" />
        <KnowMoreEmail />
      </div>
    { projects.map(
        project => <Project {...project} key={project.route} transitionFrom={transitionFrom} />
      )
    }
  </div>
)

  }

export default SelectedWork
