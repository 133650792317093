import React from 'react'

import './index.css'

import {CtaEmail as Cta} from '../../components/Cta'

const Hero =  () => (
  <div className="hero">
      <div className="headerbox">
        <h1>
        A <i>
        Design Scientist.
          </i>
          <br />
          I use design tools to      
          <br />
          explore, evaluate, 
          <br />
          and evolve brands.  
        </h1>
        <Cta />
      </div>
  </div>
)

export default Hero
