import React from 'react'
import Pages from './Pages'

import './index.css'

const Menu =  (
    {nextItem, visible}) => {

                let className = 'menu'

                if (nextItem) className += ' withnav'
                if (visible) className += ' open'
 
                return (
                  <div className={className}>
                    <Pages />
                  </div>
                )
              }

export default Menu
