import React, { Component } from 'react'

import projectData from '../data'

import HomepageContent from './Home'

class Homepage extends Component {
  componentDidMount() {
    projectData.forEach(
      datum => {
        const img = new Image()
        img.src = datum.headerImage.image
      }
    )
  }

  render() {
    return <HomepageContent />
  }
}

export default Homepage
