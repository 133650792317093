import React from 'react'
import parse from 'html-react-parser'

import './index.css'

import { CtaHome } from '../../../components/Cta'

const ItemHero = item => (
  <div className="itemhero">
      <div className="headerbox">
        <div className="titlebox">
          <h2 className="item-title">
            { item.title}
          </h2>
          <h1>
            { parse(item.headline) }
          </h1>
        </div>
        <CtaHome />
      </div>
  </div>
)

export default ItemHero
