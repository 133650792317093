import React from 'react'
import CtaEmail from '../Cta'
import './style.css'

const Contact = () => (
  <div className="contact">
  Kate El-Bizri
  <br />
  +14155162344
</div>
)

const Tail =
        () => (
            <div className="tail">
              <Contact />
              <h1>
                Let's Connect
              </h1>
              <CtaEmail />
              <div className="separator" />
              <div className="links">
                <a href="https://www.linkedin.com/in/kateelbizri/" rel="noopener noreferrer"target="_blank">
                  LinkedIn
                </a>
                <br  />
                <a target="_blank" href="KEB_Resume_2023.pdf">
                  Resume
                </a>
              </div>
              <br />
              @2023 Kate El-Bizri
              <br />
              Site development by Jonathan El-Bizri
            </div>
        )

export const TailNoContact = () => {
  return (
    <div className="tail">
      <h1>
        Let's Connect
      </h1>
      <CtaEmail />
      <div className="separator" />
      <div className="links">
        <a href="https://www.linkedin.com/in/kateelbizri/" rel="noopener noreferrer"target="_blank">
          LinkedIn
        </a>
        <br  />
        <a href="#top">
          Resume
        </a>
      </div>
      <br />
      @2023 Kate El-Bizri
      <br />
      Site development by Jonathan El-Bizri
    </div>
)
}

export default Tail
