import React from 'react';

import './index.css';

const Toggle = 
        ({onClick, toggleState}) => {
  
          const icon = 
                  toggleState
                  ? <div className='toggle-close'>
                      <svg width="2rem" height="2rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.41421" y1="1.58579" x2="29.6985" y2="29.8701" stroke="#1C1B17" strokeWidth="4"/>
                        <line y1="-2" x2="40" y2="-2" transform="matrix(-0.707107 0.707107 0.707107 0.707107 31.3359 3)" stroke="#1C1B17" strokeWidth="4"/>
                      </svg>
                    </div>    
                  : <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="2rem" viewBox="0 0 46 32" fill="none">
                      <path d="M3 3L43 3" stroke="#E56655" strokeWidth="5" strokeLinecap="square"/>
                      <path d="M3 16L43 16" stroke="#E56655" strokeWidth="5" strokeLinecap="square"/>
                      <path d="M3 29L43 29" stroke="#E56655" strokeWidth="5" strokeLinecap="square"/>
                    </svg>
            
  return (

  <div onClick={onClick}className="toggle">
    {icon}
  </div>

)
                        }

export default Toggle