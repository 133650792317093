import React from 'react'
import provideScrollPosition from 'react-provide-scroll-position'

import './style.css'

const PageDecorations =
        ({
          scrollTop
        })  => (
          <div>
            <div className="decoration top" style={{opacity: 4/(scrollTop+1)}}>
              <img className="decoration-img" alt="more-arrow" src="/images/Embellishments-01.svg" />
            </div>
            <div className="decoration left" style={{opacity: 4/(scrollTop+1)}}>
              <img className="decoration-img" alt="more-arrow" src="/images/Embellishments-02.svg" />
            </div>
            <div className="decoration right" style={{opacity: 4/(scrollTop+1)}}>
              <img className="decoration-img" alt="more-arrow" src="/images/Embellishments-03.svg" />
            </div>
          </div>
        )

export default provideScrollPosition(PageDecorations)
