import React from 'react'

import Menu from '../components/Header'
import ItemDetail from './ItemDetail'
import Tail from '../components/Tail'

const ItemPage =
    (props) => {
      const { data, route } = props
      const idx = data.findIndex(datum => datum.route === route)
      const datum = data[idx]

      const onClick =
          () => {
            window.location.href  = '#'+route+"_"+window.scrollY+"_"}

      return (
          <div className="content" >
            <div className="page">
              <div className="height-based-constrainer">
              <Menu />
                <ItemDetail
                  onClick={onClick}
                  {...datum}
                  idx={idx}
                />
                <Tail />
              </div>
            </div>
        </div>
      )
    }

export default ItemPage
