
const mina = { 
    route: `mina`
    , title: `Mina Protocol`
    , headline: `Creating an inclusive
    space for developers to
    build with zero-
    knowledge technology`
    , CTA : {
        text: `Return to Home Page`
    , link: '/'
    }
    , description: {
        header: `Introducing Mina, The World’s Lightest Blockchain, Powered by Participants`
    , copy: `<p>
    Mina is a succinct zero-knowledge blockchain, which is really just complicated words for a very small, lightweight decentralized system that allows for private transactions to occur, quickly and with very little data involved.
<br /><br />    
The brand features a variable spectrum that can be cropped in myriad ways to provide a message of inclusivity, openness, possibility, and light—a stark difference from the perception of blockchain/crypto at the time. 
<br/><br/>
    With a small design team and a heavy workload, marketing had to step in and participate in the design process. I directed a design system that kept the messaging and imagery simple, and lead short training sessions on design basics to empower non-designers to create beautiful, effective content.
<br /><br />
    A key piece of the Web3 world is a decentralized sharing of assets within a community; I had to rethink how non-designers would interact with and use brand elements in their projects. To address this, I created a brand page for the website that not only provided assets in an immediate, usable way, but also spoke to the strategy behind the brand, to educate developers on how to use the elements purposefully.
<br /><br /> 
    We took this education aspect further and created a video series educating developers on Mina’s technology, a series that has proven to have more engagement than any other videos on Mina’s YouTube channel. With this success, a new season based on this series began development this summer.
<br /><br>
    </p>
`
    , CTA : {
        text: `Check out the full videos here`
    , link: 'https://youtube.com/playlist?list=PL5RjxAODXuK0TqgeEQMZNZzCRUjPStuRk&si=27f7TqSlLm2Bgrvh'
    }
    }
    , team : [
            "Stephanie Szarwark, designer",
            "Lauren Konig, motion graphics",
            "Corey Kujawski, motion graphics",
            "Andrea Rodriguez, marketing",
            "Madeline Hengst, marketing",
            "Claire Kart, marketing",
            "Natasha Carter, marketing",
            "Sherry Lin, marketing",
            "Lindsay Casale, marketing",
            "Kerry DeJong, marketing",
            "Alex Peter, marketing analytics",
            "O(1) Labs developer relations team",
            "Brandon Kase, talent"
    ]
    , headerImage: {
        caption: `Mina Protocol`
        , image: `media/MinaProtocol/1_MP_HERO_Website2023.png`
    }
    , sections : [
        {
        caption: `Wordmark`
        , image:  `media/MinaProtocol/2_MP_Wordmark_Website2023.png`
        }
    , {
        image:  `media/MinaProtocol/3_MP_Pattern_Website2023.png`
        , caption: `Flexible Pattern and Spectrum`
        }
    , {
        video:  `media/MinaProtocol/4_MP_BrandGuide_Website2023.mp4
        `
        , caption: `Brand Guidelines Page`        
        }    
    , {
        video:  `media/MinaProtocol/5_MP_Social_Website2023.mp4`
        , caption: `Social Media Top Performers`        
        }    
    , {
        video:  `media/MinaProtocol/6_MP_VideoQuad_Website2023_ALT.mp4`
        , caption: `Video Content Top Performers`        
        }   
    ,         
    {
        caption: `Event Speaker Presentation Examples`
        , image:  `media/MinaProtocol/7_MP_Presentations_Website2023.png`
        , copy: {
            text:`<p><strong>Events: Mina’s opportunity to directly impact their target developer audience</strong>
        <br /><br />
        Mina started attending global hackathon events in 2021, with regular presentations of the technology aiming to draw in builders to use Mina for their projects to win prizes. From a design strategy perspective, the goal was two-fold: Teach people about Mina’s tech so they feel they can use it immediately, and draw in developers with FOMO swag that gets people asking “Where did you get that?”, creating an organic engagement loop. 
        <br /><br />
        The presentations were designed for developers to edit—as the technology was sometimes updated hours before the presentation, the slides had to be very easy to edit yet still provide the impact that excellent design can give.
        <br /><br />
        We also created a collection of “Spectrum Swag” that is still requested to this day, and to go along with those pieces, a series of “Zero Knowledge Proof” t-shirts were produced in a different color for each global event, providing a “collect them all” game for attendees. A year later, they were still being requested at events.
        </p>`}
    }
,   {
        caption: `Event Presence`
        , image:  `media/MinaProtocol/8_MP_Events_Website2023.png`
    }
,   {
        caption: `Strategy Pitch Deck Excerpts to Key Stakeholders`
        , video:  `media/MinaProtocol/9_MP_BrandStrat_Website2023.mp4`
        , copy: {
            text:`<p><strong>Evolving to meet the audience needs</strong>
        <br /><br />
        After a couple of years with the brand, the design team and I conducted an extensive audit, with input from social media metrics, website analytics, and organic input from across the organization and into the community. The Mina product offering was changing, and so this served as an opportunity to check in with our developer audience (and indeed, evaluate if our audience was still developers at all) with our brand—was it communicating the offering, did the brand resonate with their needs, and how did they feel when interacting with Mina. 
        <br /><br />
        We found that yes, our audience was still developers, but that the complex technology and changing product offering meant they needed guidance and direction. With that learning we adapted our system to fit more of an interactive museum approach, where Mina is guiding ZK developers throughout their building journey, providing clear messaging, educational content through multiple outlets, and relevant connection moments in person at events. 
        <br /><br />
        As a result of only 6 months with this approach, developers building on Mina at hackathons reached an all-time high—each event set a record from the previous one—and individual businesses are now building on Mina, more every month.     `
         }   
        }
    ,   {
        caption: `Brand Evolution: Social, Events. <i>Design credit: Stephanie Szarwark</i>`
        , image:  `media/MinaProtocol/10_MP_BrandEvol_Website2023.png`
    }
    ]
}



export default mina 
