import React from 'react'
import './style.css'

const Email =
        () => (
          <div className="email">
  <a target="_blank" href='&#109;ailto&#58;thi&#115;isk&#97;&#116;%&#54;5&#64;k&#97;&#116;eelbiz&#114;i&#46;%6&#52;esign'>Shoot me an email.</a>
  </div>
)


export const KnowMoreEmail =
        () => (
          <div className="email">
  <a target="_blank" href='&#109;ailto&#58;thi&#115;isk&#97;&#116;%&#54;5&#64;k&#97;&#116;eelbiz&#114;i&#46;%6&#52;esign?subject=I would like to see more work.'>More upon request</a>
  </div>
)

export default Email
