import React, { useState } from 'react'

import './index.css'

import Menu from './Menu'
import Toggle from './Toggle'
import NextItemButton from './NextItemButton'

const Menubox = 
        props => {
                    let className = 'menubox'

                    const { nextItem }  = props
                    if ( nextItem ) className += ' withnav'

                    const [ toggleState, toggleSet ] 
                            = useState( false )
                    if (toggleState) className += ' open'

                    const onToggleClick =
                            toggleState => toggleSet( !toggleState )            
                          
                    return (

<div className={className}>

    <Toggle 
      onClick={() => onToggleClick( toggleState )}
      toggleState={toggleState}
    />
    
    <Menu visible={toggleState} { ...props } />

    {/* { 
      props.nextItem
        ? <NextItemButton { ...props } />
        : null
    } */}

</div>
)
                  }

export default Menubox