import React from 'react'

import './index.css'

const ClientList = () => (
  <div className="client-list" >
    <div className="clients">
      <h3>Client List</h3>
      <ul>
        <li>Mina Foundation</li>
        <li>Mina Protocol</li>
        <li>O(1) Labs</li>
        <li>Gridspace</li>
        <li>Western Digital</li>
        <li>Walmart</li>
        <li>Dreyers</li>
        <li>SpoonfulOne</li>
        <li>T Mobile</li>
        <li>Amazon / Lab126</li>
        <li>Darren Shaw</li>
        <li>Brandi Glanville</li>
        <li>Albertsons / Safeway</li>
        <li>Swanson Vitamins</li>
        <li>Ross</li>
        <li>Tauser Apiaries</li>
        <li>Pomegranate Kitchen</li>
        <li>Golden Gate Parks Trust</li>
        <li>Anheuser-Busch</li>
        <li>Suntory</li>
        <li>Lipton</li>
        <li>Boisset, International</li>
        <li>Diageo</li>
        <li>Roots Run Deep</li>
        <li>Rockford Press</li>
      </ul>

    </div>

    <div className="agencies">
      <h3>Agencies</h3>
      <ul>
        <li>Astro Studios</li>
        <li>Sterling Brands</li>
        <li>Ammunition</li>
        <li>Anthem</li>
        <li>Deutsch Design Works</li>
        <li>CF Napa Brand Design</li>
        <li>Mine</li>
      </ul>
    </div>
  </div>
)

export default ClientList
