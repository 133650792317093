const blockade = { 
    route: `blockade`
    , title: `Blockaid`
    , headline: `Providing a design parachute for a early-stage cybersecurity startup.`
    , CTA : {
        text: `Return to Home Page`
    , link: '/'
    }
    , description: {        
        copy: `<p><strong>Blockaid: Web3, Secured.</strong>
        <br /><br />
        Companies launching out of stealth mode rarely have budget to hire a full-time designer. As a result, they need templates. Lots of templates. Templates for social, presentations, tear sheets, and modules for web, amongst others. And all these need to be easy for non-designers to edit, while maintaining the elegant aesthetics of the new brand investment.
        <br /><br />
        This means keeping design elements simple, providing many options as far as with headlines, without headlines, with partner logos, without partner logos, etc. Key to this is empathy for where this team is coming from. They're not concerned with detailed kerning and hanging punctuation; they're building a cybersecurity business and tirelessly working on partner acquisition. As such they need to move fast and have design deliver, in a fool-proof way, so they can get back to building. While some might see this as a compromise in terms of design, I see it as a fun challenge to see how I can teach people to be their own designer, how to look for the first read on a page, how to balance content with impact, and the glories of white space.
        </p>
        
    `
    }
    , team : [
        "Jason Karsh, CMO",
        "Ido Ben-Natan, CEO"
    ]
    , headerImage: {
        caption: `Blockaid`
        , image: `media/Blockaid/1_BLOCK_HERO_Website2023.png`
    }
    , sections : [
        {
        caption: `Blog Post Templates`
        , image: `media/Blockaid/2_BLOCK_Blog_Website2023.png`
        }
    ,   {
        caption: `Social Media`
        , video: `media/Blockaid/3_BLOCK_Social_Website2023.mp4`
        }
    , {
        video: `media/Blockaid/4_BLOCK_SiteFlow_Website2023.m4v`
        , caption: `Website`
        }
    , {
        video: `media/Blockaid/5_BLOCK_OnePgr_Website2023.mp4`
        , caption: `One-pager Reskin`        
        }    
    ]
}

export default blockade
