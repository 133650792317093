import React from 'react'

import './index.css'

import Menubox from './Menubox'

const Menubar =
        (props) => (
          <div>


<div className="menubar">
  <Menubox {...props} />
</div>
  <div className="header-content">
    Kate El-Bizri
    <br />
    +14155162344
  </div>
</div>

)

export default Menubar
