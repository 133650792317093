import React from 'react'

import ImageDisplay from '../ImageDisplay'

import './index.css'

const Project =
        props => {

          const onClick =
                  () => {
                        window.location.href  = '#'+props.route
                        window.scrollTo(0,0)
                  }

          const {
            headerImage,
            hideTitle
          } = props
          return (
            <div className="project">
              <div onClick={onClick} className="item">
               <div className="product-img-holder">
                 <ImageDisplay className="product-img" alt={headerImage.title} {...headerImage} hideTitle={hideTitle}/>
               </div>
              </div>
            </div>
        )}

export default Project
