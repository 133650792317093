import React from 'react'

import Email from './Email'
import navigateTo from '../navigateTo'

import './index.css'

const CTAArrow = () => (
  <img alt="next item arrow" src="images/arrow-CTA.svg" />
)

export const CtaEmail = () => (
  <div className="cta">
    <div className="CTA-text">
      <Email />
    </div>
    <CTAArrow />
  </div>
)

const PDFResume = () => (
  <div className="CTA-text">
    <a target="_blank" href="KEB_Resume_2023.pdf">PDF Resume</a>  
  </div>
)

export const CtaResume = () => (
  <div className="cta resume">
  <CTAArrow />
  <PDFResume />
</div>
)

export const GoHome = () => (
  <a 
    className="cta-text"
    onClick={navigateTo('#')} 
  >
    Return to homepage
  </a>
)

export const CtaHome = () => (
  <div className="cta">
  <CTAArrow />
  <GoHome />
</div>
)


export default CtaEmail
