const gridspace = { 
    route: `gridspace`
    , title: `GridSpace`
    , headline: `Bringing a human face to an AI product.`
    , CTA : {
        text: `Return to Home Page`
    , link: '/'
    }
    , description: {
        copy: `<p>
        <strong>
        Gridspace: AI technology before the boom 
        </strong>
        <br/> <br />In 2020, Gridspace, an AI-based call center SaaS approached
me to update the feel of their website, but in the first
conversations with the CEO and CMO, we realized that a brand
refresh was also needed to correct the perception that their AI
technology, Grace, was a cold, distant, and unfeeling alternative
to a person.
<br />
<br />
I brightened up their existing color palette of teal and salmon to a
vibrant cyan and coral, and brought in a warm brown to provide a
softer alternative to black. With the CMO, I crafted the copy away
from traditional marketing-speak and towards a more human,
friendly vibe.
<br />
<br />
The site’s presenation was reorganized, with the more complex,
technical details placed towards the end. Key takeaways were
emphasized with a highlight or with a “soundbar” icon, further
communicating the AI technology’s listening feature.
This project was completed, from conception to final style guide
and file delivery, in a whirlwind 2-week sprint.
</p>
    `
    }
    , team : [
        "Jason Karsh, CM",
        "Evan MacMillan, CEO"
    ]
    , headerImage: {
        caption: `Gridspace`
        , image: `media/Gridspace/1_GRID_HERO_Website2023.png`
    }
    , sections : [
        {
        caption: `Home Page`
        , video: `media/Gridspace/2_GRID_Home_Website2023.mp4`
        }
    ,   {
        caption: `Call Center Page`
        , video: `media/Gridspace/3_GRID_Call_Website2023.mp4`
        }
    , {
        image: `media/Gridspace/4_GRID_Human_Website2023.png`
        , caption: `Human Moments`
        }
    , {
        image: `media/Gridspace/5_GRID_Guide_Website2023.png`
        , caption: `Guidelines`        
        }    
    ]
}

export default gridspace


