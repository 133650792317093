import React from 'react'
import ScrollManager from '../helpers/ScrollHelper'

import './index.css'
import './quotes.css'

import Hero from './Hero'
import { CtaResume } from '../components/Cta'
import ClientList from './ClientList'
import SelectedWork from './SelectedWork'

import Menu from '../components/Header'
import LatestProject from './LatestProject'
import PageDecorations from '../components/PageDecorations'

import Tail from '../components/Tail';

const HomepageContent =
        ({
          transitionFrom
          }) => (
            <>
              <div className="page">
                <Menu />
                <ScrollManager scrollKey="Homepage" />
                <Hero />
                <LatestProject />
                <div className="work">
                  <CtaResume />
                  <ClientList />
                </div>
                <SelectedWork transitionFrom={transitionFrom}/>
                <Tail />
                <PageDecorations />
              </div>
              {/* <div className="overlay" /> */}
            </>
 
)

export default HomepageContent


// <ScrollManager scrollKey="Homepage" />
// <Hero />
