import React from 'react'

import navigateTo from '../../../navigateTo'

const MenuItem =
        ({location, name}) => {
                                if (name === 'Resumé') 
                                  return <a className="menuitem" target="_blank" href="KEB_Resume_2023.pdf">{name}</a>

                                const current = 
                                        window.location.href.split('#')[1] || ''

                                const className = 
                                        "menuitem" + ( (current === location) ? " current" : "")

                                return (
                                  <span className={className} onClick={navigateTo('#' + location)}>{name}</span>
                                )
                              }

export default MenuItem
