import React from 'react'

import Menu from '../components/Header'
import Tail from '../components/Tail'

import './index.css'
    
const About = ({data, transitionFrom}) => (
  <div className="page">
    <div className="pagecontent heropagecontent">
      <Menu/>
      <div className="hero">
      <h3 className="topheader">Pov/hot takes</h3>
          <div className="headerbox">
            <h1>
              Science is, at heart, a study of observations. Here’s some of mine.
            </h1>
          </div>
          <div className="about description">
            <p>
              <strong>
                Brands are so much more than a logo
              </strong>
              <br/>
              <br/>
                They’re even more than just the visuals. The visual system - logo, color, typography, image style - these are valuable communication tools by which we connect your brand to your audience. The brand defines those tools. So, what is a brand, then?
              <br/>
              <br/>
                  Your brand is the feeling your audience gets when they interact with any touchpoint, large or small, from your product or company. It’s the “aaah” feeling after a sip of cold soda on a hot day. It’s the empowerment feeling when you get into a new car and every button is exactly where you expect it to be, without word identifiers. It’s the “you know me” connection feeling that has you buying the product, year after year, no matter how many clone products come out - you know the real thing when you experience it.
            </p>
            <p>
              <strong>
                You, as a founder, are the most important  ambassador for your brand.
              </strong>
              <br/>

                <br/>
                Everyone looks to the leader for direction, and this goes way beyond the internal vision for a company. A leader’s presence on social media is one of the most powerful brand platforms that exists, for better or worse. 
                <br/>
                <br/>
                What this means for founders is that when working with a designer on your brand, you have to be honest and very clear to your design team on who you are, who your company/product is, and who you are trying to reach. When the design team presents their solutions to you, those solutions are based on this information. You have the power to affect people’s perception of your brand; use that power wisely.
                <br/>
                <br/>
                This also means when you sign the approval for a brand direction, make sure you will stand behind it. If what’s in your heart is flashy hype, don’t sign the OK for a brand that’s soft and chill. This does both your design team and, much more importantly, your own team and company, a huge disservice.
            </p>
            <p>            
              <strong>
                Marketing and Brand are not the same thing
              </strong>
              <br/>
              <br/>
                It’s an oldie but a goodie: Marketing is asking someone out on a date, Brand is what makes them say “yes”.
              <br/>
              <br/>
                Many marketers thing brand fits under marketing; I believe that both marketing and design nestle under brand. If your brand is solid, all other decisions, strategic or aesthetic, should follow from this core. Like all scientific experiments, there is a place for exploration as well as a space for evaluation, interpretation, and evolution, but every experiment follows the central hypothesis and rules around what we know so far. 
          </p>
          <p>
            <strong>
              Know what you need
            </strong>
            <br/>
            <br/>
            I firmly believe that you don’t need a tank to open a locked door. Sure, it gets the job done, but it’s expensive, destructive, and no one knows what to do with it once the objective is achieved. This isn’t effective problem solving. 
            <br/>
            <br/>
            If you’re an early-stage startup, you do not need a big design agency to create a huge brand system full of web, animation, video, merch, and events. You need the basics, because what your company really needs at this stage is a product that ships and investors to get their ROI. This is where a generalist designer (such has myself) or boutique design team comes in - they can deliver a solid brand for you within the budget you have that allows your product to progress.
          </p>
        </div>
      </div>
      <div className="hero">
      <h3 className="topheader">About</h3>
          <div className="headerbox">
            <h1>
            I believe everyone is creative, and design is for everyone
            </h1>
          </div>
          <div className="about description">
            <p>
            <strong>
              Where I'm coming from
              </strong>
              <br/>
              <br/>
            I didn't start out as a designer. My first degree is in psychology, with interest in motivation and emotion—what drives us to make the decisions we make, what keeps us inspired in our work, and what is our kryptonite when it comes to accomplishing our goals. All my design work has stemmed from these basic principles, whether in packaging, branding, digital, or marketing design—understanding motivations and drivers defines success of a project, and creates learnings for evolutions.
            </p>
            <p>
            <strong>
            Creatively inspiring others
              </strong>
            <br/>
            <br/>
            I model my leadership style after those managers I had early in my career who kept me going when I struggled with inspiration. An empathetic ear, transparent and timely communication, partnered with clear expectations of what was needed built my confidence and empowered me to do my best work, and this is what I strive to bring to all I work with. No one solution works for everyone; it's important to hear where people are at, what they are struggling with, what gets them up in the morning, to know what blocks to remove so they can shine their brightest. 
            </p>
            <p>
            <strong>
            Connecting humans to content
              </strong>
            <br/>
            <br/>
              Throughout my career, the common thread of success that I've seen is wrapped around the idea of forming connections between the audience and the product. One of the most rewarding spaces for this is with content. We live in an ever-increasing information-rich attention-grabbing age, and sometimes it's easy to get lost in the "Can we be louder than the other guys" or "Can we be prettier" or "Can we be more trendy". What works, though, time and again, is finding that human emotional connection. Emotions drive decision-making.Throughout my work you'll see glimpses of this concept, sometimes more obvious, sometimes just a tagline, because I believe we are most successful in getting people to make decisions—Should I buy this product?—when we can speak "in human" to our audience.
            </p>
            <p>
            <strong>
              A bit about me
              </strong>
              <br/>
              <br/>
                <i>Location:</i>
                San Francisco, CA, USA
              <br/>
              <br/>
                <i>
                Favorite stress reducers:
                  </i> Walking with my ancient dog, throwing pots on a wheel, vinyasa yoga, boxing, gardening, and eating/making all the food.
              <br />
              <br/>
                <i>
                Working style:
                  </i>
                   I’m a firm believer in regular 1:1s, even if there isn’t anything to discuss immediately, the safe space created from regularly prioritizing reports allows for blockers to come up that wouldn’t normally be voiced. This is also a great chance to check in about successes. Work can often slide into frameworks of "What's not working" that we lose sight of what IS working. To keep motivated, we have to feel positive about our impact, and we need others to recognize our successes as well.
              <br />
            </p>
        </div>
      </div>
    </div>
    <Tail />
 
  </div>
  )

export default About
