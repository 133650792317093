import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import * as serviceWorker from './serviceWorker';

import data from './data'

import Router from './router'

function onDataChange() {
  // Splits location into `/` separated parts,
  const location = window.location.hash.replace(/^#\/?|\/$/g, '').split('/');
  // Gets the DOM Element to render to
  const content = document.getElementById('root')
  // Renders with the location and the data
  ReactDOM.render(<Router data={data} location={location}/>, content)
}

window.addEventListener('hashchange', onDataChange, false);

onDataChange()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
