import React from 'react'

import data from './data'

import Homepage from './pages/index'
import ItemPage from './pages/Item'
import About from './pages/about'
import Resume from './pages/resume'
import Contact from './pages/contact'

const routes = data.map(datum => datum.route)

const NotFound =
        () => <div>
                Page Not Found
              </div>

const getRoute =
        ( hash
        , props
          ) => {
                  //Static routes
                  switch (hash)  {
                    case '':
                      return <Homepage {...props} />;
                    case 'About':
                      return <About {...props} />;
                    case 'Contact':
                      return <Contact {...props} />;
                    default:
                      return <Homepage {...props} />;
                  }
                }

const Router =
    props => {
                    const hash = props.location[0]

                    //Data routes
                    if (routes.includes(hash)) return <ItemPage {...props} route={hash} />

                    const transitionFrom =
                            (hash.endsWith('_'))
                            ? hash
                            : null

                    const route = getRoute(hash, {...props, transitionFrom })

                    return route
                  }

export default Router
