const sterling = { 
    route: `sterling`
    , title: `Sterling Brands`
    , headline: `Early stage brand design, management and handoff`
    , CTA : {
        text: `Return to Home Page`
    , link: '/'
    }
    , description: {
        header: ''
    , copy: `<p>At Sterling Brands, a branding design agency, I worked on myriad projects, some as small as a one-off jar of cherries for Albertsons/Safeway, some as large as full-scale systems for Dreyer’s. Here’s a few of those projects; I have more to share upon request.</p>
    <br />
    <p><strong>
    Easing the fear of first-time parenthood.
    </strong>
    </p>
    <p>
        SpoonfulOne, a startup with a product that looks scary from the outside, came to Sterling to soften the fear they expected from their target audience: new parents. Their product was an allergen supplement that parents add to their kids’ food to prevent food allergies, so we gave it a friendly, vibrant, yet clean and almost medical vibe to get past this emotion hurdle.
    </p>
    <p>
        Continuing in this empathetic vein, we designed some cards that could be mailed out to new parents, on one level reminding them of the product, but on another one reassuring them that yes, that poop is normal, and yes, it’s hard to get your baby to burp. A little understanding goes a long way in consumer retention, and these cards helped keep them renewing their subscriptions every month.
    </p>`
    }
    , team: [
        "Philippe Becker, ECD",
        "Lia Gordon, Design Director",
        "Dylan Schepers, Senior Designer"
    ]
    , headerImage: {
        caption: `Sterling Brands`
        , image: `media/Sterling/Sterling_1.jpg`
    }
    , sections : [
        {
        caption: `Retention cards and Founder's letter `
        , image: `media/Sterling/Sterling_2.jpg`
        }
    , {
        copy: {
            text: `
            <p><strong>A fun habit to make</strong></p>
            <br />
            <p>
            Sweet Habit was a new brand brought to Sterling by Dreyers, with a 3-day timeline in tow for brand creation. This brand wasn’t just fun to create from an illustrative standpoint—though who doesn’t love penguin characters—it also served as a chance to flex my naming and copywriting skills.
            </p>
            <p>
            Adding a dollop of personality into an ice-cream brand is an ideal strategy; ice cream is a fun product that is celebratory and joyful by nature, so capitalizing on that human experience was a no-brainer.
            </p>
        `
      , team: [
        `Butler Looney, Creative Director`,
        `Lia Gordon, Design Director`,
        `Stephanie Szarwark, Designer`,
        `Georgia Thunes, Strategy`]
      }
    }
    , {
        caption: `Sweet Habit Packaging`        
    , image: `media/Sterling/Sterling_3.jpg`
        }    
    , {
        image:  `media/Sterling/Sterling_4.jpg`
        , caption: `Sweet Habit Character Lineup`        
        }       
    , {
        copy: {
            text:`
        <p>
        <strong>
            Dog goods for good dogs
        </strong>
        </p>
        <br />
        <p>
        A quick-turnaround project for Ross Dress-for-Less, Wag ‘n Woof is a ghost store brand (owned by the store but not advertised under the Ross brand) I developed for pet supplies. 
        </p>
        <p>
        If you’ve ever been to a Ross, you’ll know it’s complete chaos on the shelves; this meant the packaging had to be big, bold, and bright without a lot of extra bells and whistles to stand out. The brand, some 4 years later, is still carrying strong in Ross stores throughout the country, a testament to the successful strategy.
        </p>
        `
      , team: [  'Jay Cabalquinto, Creative Director']
        }
    }
    , {
        caption: `Wag'n Woof Logo`        
    , image: `media/Sterling/Sterling_5.jpg`
        }    
    , {
        image:  `media/Sterling/Sterling_6.jpg`
        , caption: `Packaging Explorations`        
        }    
    , {
        image:  `media/Sterling/Sterling_7.jpg`
        , caption: `Wag 'n Woof Style Guide Excerpts`        
        }   
    ]
}

export default sterling